<template>
  <div class="modal-task">
    <b-modal id="modal-task" centered hide-footer @hidden="resetModal">
      <template #modal-title>
        <div class="modal-task__title">
          {{ isModalTypeCreated ? $t('header.createTask') : $t('header.editTask') }}
        </div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <div
          class="selected-material"
          :class="{ 'selected-material--creation': isModalTypeCreated }"
        >
          <div class="selected-material__info info">
            <div class="info__title">
              {{ modalTitle }}
            </div>
            <div class="info__subtitle">
              {{
                isModalTypeCreated
                  ? $t('supportText.canAttachAnyFormatFile')
                  : $t('supportText.canReplaceFileTitleDescription')
              }}
            </div>
          </div>
          <div class="selected-material__action meta">
            <div class="meta__title">
              <LmsInput
                id="material-title"
                :label="$t('placeholders.enterTaskTitle')"
                :value.sync="title"
                @change="() => $v.title.$touch()"
                :class="{
                  invalid: $v.title.$dirty && !$v.title.required,
                }"
              />
            </div>
            <div v-if="$v.title.$dirty && !$v.title.required" class="helper-text">
              {{ $t('errorMessages.pleaseFill') }}
            </div>

            <div class="meta__description">
              <LmsInput
                id="material-description"
                type="textarea"
                :label="$t('placeholders.enterTaskDescription')"
                :value.sync="selectedMaterial.text"
              />
            </div>

            <div class="meta__file-section file-section">
              <div class="file-section__left">
                <AddButton
                  :title="buttonFileLabel"
                  size="small"
                  :variant="[isFileSelected ? 'secondary-file' : 'secondary'][0]"
                  @click="openFile"
                >
                  <template v-if="isFileSelected" #prefixIcon>
                    <FileIcon />
                  </template>
                  <template v-if="isFileSelected" #suffixIcon>
                    <CloseBoldSmallIcon @click="removeFile" />
                  </template>
                </AddButton>
                <p class="file-section__button-label">
                  {{ $t('buttonLabels.fileWillBeAvailableForDownload') }}
                </p>
              </div>
              <div class="file-section__right"></div>
            </div>

            <div class="meta__attempt-block">
              <div class="attempt-block">
                <div class="attempt-block__item">
                  <div class="attempt-block__left">
                    <div class="attempt-block__title">
                      <p>{{ $t('label.scorePerCorrectAnswerAmount') }}</p>
                    </div>
                    <div class="attempt-block__subtitle">
                      <p>{{ $t('label.enterMaxPointsForAnswer') }}</p>
                    </div>
                  </div>
                  <div class="attempt-block__right">
                    <div class="score-container">
                      <LmsInput
                        id="material-score"
                        type="number"
                        :value.sync="max_score"
                        placeholder="max 999"
                        :class="{
                          invalid: $v.max_score.$dirty && !$v.max_score.maximumNotExceeded,
                        }"
                      />
                      <div
                        class="error-message"
                        :class="{
                          'error-message--activate':
                            $v.max_score.$dirty && !$v.max_score.maximumNotExceeded,
                        }"
                      >
                        <p>{{ $t('label.maximum') }} 999</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="attempt-block__item">
                  <div class="attempt-block__left">
                    <div class="attempt-block__title">
                      <p>{{ $t('label.limitNumbersOfAttempts') }}</p>
                    </div>
                    <div class="attempt-block__subtitle">
                      <p>{{ $t('label.byDefaultAttemptsAreUnlimited') }}</p>
                    </div>
                  </div>
                  <div class="attempt-block__right">
                    <div class="attempt-block__switcher">
                      <LmsSwitch :value.sync="isEnabledAttempts" size="large" withIcon />
                    </div>
                    <div class="attempt-block__input">
                      <input
                        ref="input"
                        v-model.number="attempts"
                        type="number"
                        class="simple-input"
                        :class="{
                          'simple-input--disabled': !isEnabledAttempts,
                          invalid: $v.attempts.$dirty && !$v.attempts.maximumNotExceeded,
                        }"
                        :disabled="!isEnabledAttempts"
                        @keypress="isNumber($event)"
                      />
                    </div>
                  </div>
                  <div
                    class="error-message"
                    :class="{
                      'error-message--activate':
                        $v.attempts.$dirty && !$v.attempts.maximumNotExceeded,
                    }"
                  >
                    <p>{{ $t('label.maximum') }} 999</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="meta__action">
              <span
                class="homework-link"
                v-if="!isModalTypeCreated"
                @click="openTaskAnswers"
              >
                  {{ $t('links.goToTaskAnswers') }}
              </span>
              <AddButton
                :isDisabled="loading"
                :title="
                  isModalTypeCreated
                    ? $t('buttonLabels.createTask')
                    : $t('buttonLabels.saveChanges')
                "
                @click="saveTask"
              />
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <input
      type="file"
      id="task-loader"
      class="d-none"
      @change="upload"
      ref="load"
      :accept="acceptedTypes"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import LmsInput from '@/components/LmsInput.vue';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import FileIcon from '@/components/Icons/FileIcon.vue';
import CloseBoldSmallIcon from '@/components/Icons/CloseBoldSmallIcon.vue';
import MaterialService from '@/services/material.service';
import LmsSwitch from '@/components/LmsSwitch.vue';

export default {
  name: 'ModalTask',
  components: {
    CloseBoldSmallIcon,
    FileIcon,
    LmsInput,
    SmallCloseButton,
    AddButton,
    LmsSwitch,
  },
  data: () => ({
    title: '',
    max_score: null,
    selectedMaterial: { text: '' },
    fileSizeLimit: 100 * 1024 * 1024, // Mbyte
    formData: null,
    loading: false,
    whiteListExtensions: ['pptx', 'docx', 'pdf', 'zip'],
    isEnabledAttempts: false,
    attempts: 0,
  }),
  validations: {
    title: {
      required,
    },
    attempts: {
      maximumNotExceeded(int) {
        return int < 1000;
      },
    },
    max_score: {
      maximumNotExceeded(int) {
        return int < 1000;
      },
    },
  },
  computed: {
    ...mapGetters({ isModalTypeCreated: 'modalType/getIsModalTypeCreated' }),
    ...mapGetters({ currentTask: 'courseContentTeacher/selectedContent' }),
    ...mapGetters('courseContentTeacher', [
      'contentLevel',
      'groupIdOfLevelOne',
      'groupIdOfLevelTwo',
    ]),

    acceptedTypes() {
      return this.whiteListExtensions.map((ext) => `.${ext}`).join(', ');
    },

    isDifferentFromInitialValues() {
      return (
        this.title !== this.currentTask.name
        || this.max_score !== this.currentTask?.max_score
        || this.selectedMaterial.text !== this.currentTask.meta?.text
        || this.selectedMaterial?.fileName !== this.currentTask.meta?.fileName
        || this.selectedMaterial?.fileSize !== this.currentTask.meta?.fileSize
        || this.selectedMaterial?.fileExtension !== this.currentTask.meta?.fileExtension
        || this.attempts !== this.currentTask.meta?.attempts
      );
    },
    modalTitle() {
      return this.isModalTypeCreated
        ? this.$t('supportText.createFreeAnswerQuestion')
        : this.$t('buttonLabels.task');
    },
    isFileSelected() {
      return !!this.selectedMaterial?.fileName;
    },
    buttonFileLabel() {
      if (this.isFileSelected) {
        return this.selectedMaterial.fileName;
      }
      return this.$t('buttonLabels.attachFile');
    },
  },
  methods: {
    ...mapActions('courseContentTeacher', ['createActivity', 'updateActivity', 'selectContentId']),
    ...mapActions({ setToaster: 'toaster/setToaster' }),
    ...mapActions('modalType', ['setModalTypeIsCreated']),
    openFile() {
      if (this.selectedMaterial?.url && !this.$refs.load?.value) {
        window.open(`https://${this.selectedMaterial.url}`);
      } else {
        this.$refs.load.click();
      }
    },

    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },

    saveTask() {
      if (this.loading) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      if (this.attempts < 0) {
        this.attempts = 0;
      }

      if (this.isModalTypeCreated) {
        this.createTask();
      } else {
        this.updateTask();
      }
    },
    async createTask() {
      const meta = { ...this.selectedMaterial, attempts: this.attempts || 0 };
      delete meta.fileSrc;
      if (!meta?.fileName) {
        return this.createTaskWithoutFile();
      }
      this.$bvModal.hide('modal-task');

      // eslint-disable-next-line no-nested-ternary
      const parentId = this.contentLevel === 0
        ? this.$route.params.courseId
        : this.contentLevel === 1
          ? this.groupIdOfLevelOne
          : this.groupIdOfLevelTwo;

      const toast = {
        file: this.formData,
        meta,
        parentId,
        title: this.title,
        activityType: 'task',
        max_score: this.max_score ? this.max_score : 0,
      };
      this.setToaster({
        type: 'upload-file',
        toast,
      });
    },
    async createTaskWithoutFile() {
      // eslint-disable-next-line no-nested-ternary
      const parentId = this.contentLevel === 0
        ? this.$route.params.courseId
        : this.contentLevel === 1
          ? this.groupIdOfLevelOne
          : this.groupIdOfLevelTwo;

      const payload = {
        title: this.title,
        max_score: this.max_score ? this.max_score : 0,
        activityType: 'task',
        meta: { text: this.selectedMaterial.text, attempts: this.attempts || 0 },
        parentId,
      };
      this.loading = true;
      await this.createActivity(payload);
      this.loading = false;
      this.$bvModal.hide('modal-task');
    },
    async updateTask() {
      if (!this.isDifferentFromInitialValues) {
        return this.$bvModal.hide('modal-task');
      }

      const meta = { ...this.selectedMaterial, attempts: this.attempts || 0 };
      delete meta.fileSrc;
      if (!this.formData) {
        return this.updateTaskWithoutFile();
      }

      this.$bvModal.hide('modal-task');

      const toast = {
        file: this.formData,
        meta,
        parentId: this.$route.params.courseId,
        name: this.title,
        max_score: this.max_score ? this.max_score : 0,
        activityType: 'task',
        isUpdateActivity: true,
        activityId: this.currentTask._id,
        type: 'task',
      };
      this.setToaster({
        type: 'upload-file',
        toast,
      });
    },
    async updateTaskWithoutFile() {
      // eslint-disable-next-line no-nested-ternary
      const parentId = this.contentLevel === 0
        ? this.$route.params.courseId
        : this.contentLevel === 1
          ? this.groupIdOfLevelOne
          : this.groupIdOfLevelTwo;

      const activity = {
        name: this.title,
        max_score: this.max_score ? this.max_score : 0,
        type: 'task',
        meta: { ...this.selectedMaterial, attempts: this.attempts || 0 },
        parentId,
      };
      this.$bvModal.hide('modal-task');
      await this.updateActivity({ id: this.currentTask._id, activity });
    },

    loadMaterial() {
      return MaterialService.uploadFile(this.formData)
        .then(async ({ data: { url } }) => {
          const meta = { ...this.selectedMaterial, url };
          delete meta.fileSrc;
          return meta;
        })
        .catch(this.serverErrorToast);
    },

    upload(event) {
      const file = this.$refs.load.files[0];
      if (file) {
        if (!this.validateFileExtension(file)) {
          return;
        }
        if (!this.validateFileSize(file)) {
          return;
        }

        this.formData = new FormData();
        this.formData.append('file', file);

        let files = [];
        files = Array.from(event.target.files);

        files.forEach((f) => {
          const reader = new FileReader();
          const splitNam = f.name.split('.');
          reader.onload = (ev) => {
            const mime = ev.target.result.split(';')[0].split('data:')[1];
            this.formData.append('type', mime);
            this.selectedMaterial = {
              ...this.selectedMaterial,
              fileSrc: ev.target.result,
              fileName: f.name,
              fileExtension: splitNam[splitNam.length - 1],
              fileSize: this.formatBytes(f.size),
            };
          };

          reader.readAsDataURL(f);
        });
      }
    },
    removeFile() {
      this.selectedMaterial = { text: this.selectedMaterial.text };
      if (this.$refs.load?.value) {
        this.$refs.load.value = null;
      }
    },
    validateFileExtension(file) {
      const spiltName = file.name.split('.');
      const flExt = spiltName[spiltName.length - 1];
      if (!this.whiteListExtensions.includes(flExt)) {
        const toast = {
          title: this.$t('errorMessages.fileNotSupported'),
          body: `${this.$t('supportText.onlyOfTheFormat')} ${this.acceptedTypes}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    validateFileSize(file) {
      if (this.fileSizeLimit < file?.size) {
        const toast = {
          title: this.$t('errorMessages.sizeToLarge'),
          body: `${this.$t('supportText.maximumFileSize')} - ${this.formatBytes(
            this.fileSizeLimit,
          )}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    formatBytes(a, b = 2) {
      if (a === 0) return '0 Bytes';
      const c = b < 0 ? 0 : b;
      const d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
      }`;
    },

    serverErrorToast() {
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },

    resetModal() {
      this.setModalTypeIsCreated(false);
      this.selectContentId(null);
      this.title = '';
      this.max_score = null;
      this.selectedMaterial = { text: '' };
      this.attempts = 0;
      if (this.$refs.load?.value) {
        this.$refs.load.value = '';
      }
      this.formData = null;
      if (this.$v) {
        this.$v.$reset();
      }
      this.$emit('unmountModal');
    },
    openTaskAnswers() {
      this.$router.push({
        name: 'CheckHomeworksPage',
        query: { taskId: this.currentTask._id },
        params: { path: this.$route.fullPath },
      });
      this.$bvModal.hide('modal-task');
      this.resetModal();
    },
  },
  watch: {
    isEnabledAttempts(bool) {
      if (!bool) {
        this.attempts = 0;
      }
    },
    currentTask: {
      handler(activity) {
        if (activity && activity.type === 'task') {
          this.title = activity.name ?? '';
          this.max_score = activity.max_score ?? null;
          this.selectedMaterial = { ...activity.meta } || { text: '' };
          this.attempts = activity.meta?.attempts || 0;
          this.isEnabledAttempts = this.attempts >= 1;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
